/* global vad */ // Inform ESLint that 'vad' is a global variable

let mediaRecorder;
let socket;
let vadInstance;
let isSocketOpen = false;
let recordingStartTime;
let elapsedRecordingTime = 0; // Tracks active transmission time
let timerInterval;

export const startRecording = async (setLatestNote) => {
  try {
    // First, make a regular HTTP request to get a temporary token
    const authResponse = await fetch('/api/ws-auth-token', {
      method: 'POST',
      credentials: 'include'  // This ensures cookies are sent
    });

    if (!authResponse.ok) {
      throw new Error('Failed to get WebSocket authentication');
    }

    const { tempToken } = await authResponse.json();

    // Use the temporary token in the WebSocket URL
    socket = new WebSocket(`wss://app.noteician.com/recording?token=${tempToken}`);


    socket.onopen = async () => {
      isSocketOpen = true;
      console.log('WebSocket connection opened for recording.');

      // Request microphone access
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Initialize MediaRecorder
      // Function to get the best-supported MIME type
      function getSupportedMimeType() {
        const mimeTypes = ['audio/webm; codecs=opus', 'audio/webm', 'audio/mp4'];
        return mimeTypes.find(type => MediaRecorder.isTypeSupported(type)) || null;
      }

      // Initialize MediaRecorder with the supported MIME type
      const selectedMimeType = getSupportedMimeType();
      if (!selectedMimeType) {
        alert('Audio recording is not supported on your device/browser.');
        return;
      }
      mediaRecorder = new MediaRecorder(stream, { mimeType: selectedMimeType });


      // Set up VAD to control recording
      vadInstance = await vad.MicVAD.new({
        onSpeechStart: () => {
          console.log('Speech detected, resuming data transmission.');
          if (mediaRecorder.state === 'paused') {
            mediaRecorder.resume();
            recordingStartTime = Date.now(); // Set the start time only on active resume
            startTimer(); // Resume the timer when VAD resumes recording
          }
        },
        onSpeechEnd: () => {
          console.log('Silence detected, pausing data transmission.');
          if (mediaRecorder.state === 'recording') {
            mediaRecorder.pause();
            stopTimer(); // Pause the timer when VAD pauses recording
          }
        },
      });

      // Start VAD listening
      vadInstance.start();

      // Handle audio data and send it via WebSocket
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0 && socket.readyState === WebSocket.OPEN) {
          socket.send(event.data);
        }
      };

      // Start the MediaRecorder, controlled by VAD
      mediaRecorder.start(1000); // Sends data every second
      recordingStartTime = Date.now(); // Initial start time
      startTimer(); // Start the timer as recording begins
      console.log('Recording started, controlled by VAD.');
    };

    // Listen for WebSocket close event to handle "Insufficient recording time"
    socket.onclose = async (event) => {
      console.log('WebSocket connection closed.');
      isSocketOpen = false;

      if (event.reason === 'Insufficient recording time') {
        alert('You have insufficient recording time remaining. Please upgrade your plan or check with your organization.');

        // Immediately call stopRecording to ensure cleanup
        await stopRecording(setLatestNote);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      isSocketOpen = false;
    };

  } catch (error) {
    console.error('Error starting recording:', error);
  }
};

export const stopRecording = async (setLatestNote) => {
  if (mediaRecorder && (mediaRecorder.state === 'recording' || mediaRecorder.state === 'paused')) {
    mediaRecorder.stop();
    stopTimer(); // Stop the timer when recording stops
    console.log('Recording stopped.');

    // Reset timer display to 00:00:00
    elapsedRecordingTime = 0;
    updateTimerDisplay(elapsedRecordingTime);

    // Stop VAD listening without destroying the instance
    if (vadInstance && vadInstance.listening) {
      vadInstance.pause();
      console.log('VAD listening paused.');
    }

    // Close the WebSocket connection
    if (isSocketOpen) {
      socket.close();
      console.log('socket closed')
    }

    // Fetch the latest note via HTTP after the recording is stopped
    try {
      const response = await fetch('/get-latest-note', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Ensure cookies are sent with the request
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Latest Note:', data.note);
        setLatestNote(data.note); // Update the context with the latest note
      } else {
        console.error('Failed to fetch the latest note:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching latest note:', error);
    }
  }
};

export const pauseRecording = () => {
  if (mediaRecorder && mediaRecorder.state === 'recording') {
    mediaRecorder.pause();
    stopTimer(); // Pause the timer when recording pauses
    console.log('Recording paused.');
  }

  if (vadInstance && vadInstance.listening) {
    vadInstance.pause();
    console.log('VAD paused.');
  }
};

export const resumeRecording = () => {
  if (mediaRecorder && mediaRecorder.state === 'paused') {
    mediaRecorder.resume();
    recordingStartTime = Date.now(); // Reset the start time for this interval
    startTimer(); // Resume the timer when recording resumes
    console.log('Recording resumed.');

    if (vadInstance && !vadInstance.listening) {
      vadInstance.start();
      console.log('VAD resumed.');
    }
  }
};

// Timer functions to manage recording time display

const startTimer = () => {
  if (!timerInterval) {
    timerInterval = setInterval(() => {
      const currentTime = Date.now();
      elapsedRecordingTime += currentTime - recordingStartTime; // Add only active time to total
      recordingStartTime = currentTime;
      updateTimerDisplay(elapsedRecordingTime);
    }, 1000);
  }
};

const stopTimer = () => {
  clearInterval(timerInterval);
  timerInterval = null;
};

const updateTimerDisplay = (timeInMs) => {
  const totalSeconds = Math.floor(timeInMs / 1000);
  const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
  const seconds = String(totalSeconds % 60).padStart(2, '0');
  document.getElementById('timer').textContent = `${hours}:${minutes}:${seconds}`;
};
